import {useState,useEffect} from 'react'
import axios from 'axios'
import Header from '../front_page/header_front'
import Search from '../front_page/search'
import Footer from '../front_page/footer'
import '../../css/articles_by_session.css'
import { Article_child } from '../front_page/Main'
import {AiOutlineCloseCircle} from 'react-icons/ai';
import { Carousel } from 'react-responsive-carousel';



const Articles_by_session = ()=>{
    const url_parts = window.location.search.split('&');
    const journal_id = url_parts[0].substr(12)
    const volume = url_parts[1].substr(7);
    const issue = url_parts[2].substr(6);
    const [article_obj,set_article_obj] = useState([]);
    const [selected_item,set_selected_item] = useState(null);
    const [slider_name_array,set_slider_name_array] = useState([]);

    useEffect(()=>{
    window.show_loader()

        axios.get(`${window.server_url}/article_db/article_by_session^${journal_id}^${volume}^${issue}`)
        .then(response=>{
                set_article_obj(response.data)
                window.hide_loader()
            })
        .catch(error=>console.log(error))
    },[])
    return(
        <section style={{backgroundColor:'white'}} id="articles_by_session">
            <Header/>
            <Search btn_color='#4f5671' menu_color='white'/>
            {/* details of volume issue journal ,year and month */}
            <h1 className=' is-size-4-desktop is-size-5-tablet is-size-6-mobile' id='session_journal_head'>
                {
                    article_obj.length>0&&article_obj[1][0].journal_name
                }
            </h1>
            <h2 className='is-size-6' id='session_details'>
                {
                    article_obj.length>0&&'Volume-'+article_obj[0][0].volume+': Issue-'+article_obj[0][0].issue+' ('+article_obj[0][0].month+','+article_obj[0][0].year+')'
                }
            </h2>
            
            
            
            {/* big screen slider show for article */}
            <div id='big_screen_carrow'>
                <div id="big_screen_carrow_container">
                    <div onClick={()=>{
                        document.getElementById('big_screen_carrow').style.display='none';
                    }} id="main_close_btn">
                        <AiOutlineCloseCircle size={'40px'} color={'white'}/>
                    </div>
                    <Carousel selectedItem={selected_item} showIndicators={false} showThumbs={false}>
                        {
                            slider_name_array.map((slider_name,index)=>(
                                <div key={`main ${index}`}>
                                    <div className='big_img_article'>
                                        <img src={`/resources/article_slides/${slider_name}`}/>
                                    </div>
                                    <p style={{color:'white',fontSize:'1.3rem',fontWeight:'400',marginTop:'20px'}}>{`Figure ${index+1}`}</p>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
            {
                article_obj.length>0&&article_obj[0].map(article_obj=>{
                    let slider_urls=[];
                    for(let i =0;i<article_obj.slider_count;i++){
                        slider_urls.push(`${article_obj.doi_number}.${i}.png`)
                    }
                    return(
                        <Article_child
                        key={`${article_obj.doi_number}`}
                        title = {article_obj.article_title}
                        authors = {article_obj.author_name}
                        date = {article_obj.article_date.substr(0,10)}
                        doi_link = {article_obj.doi_link}
                        abstract = {convertToPlain(article_obj.abstract_content)}
                        slider_name_array={slider_urls}
                        set_selected_item = {set_selected_item}
                        set_slider_name_array={set_slider_name_array}
                        doi_number ={article_obj.doi_number}
                        article_file_name={article_obj.article_file_name}
                        // article_child_style={{marginLeft:'45px',marginTop:'30px',marginBottom:'30px',marginRight:'30px'}}
                        download_icon_size='20px'
                        volume = {article_obj.volume}
                        issue = {article_obj.issue}
                        id={article_obj.id}
                        page_no={article_obj.page_no}
                        article_abbrevation={article_obj.article_abbrevation}
                        />
                    )
                  
                })
            }
            
            <Footer/>
        </section>
    )
}

export default Articles_by_session;

function convertToPlain(html){

    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}
