import Header from "./front_page/header_front"
import Footer from "./front_page/footer"
import '../css/payments.css'
import axios from "axios"
import { useState,useEffect } from "react"
import PhoneInput from "react-phone-input-2"
import {FaUserAlt,FaAffiliatetheme} from 'react-icons/fa'
import {IoMdMail} from 'react-icons/io'
import {BiMoney} from 'react-icons/bi'

const Payments = ()=>{
    const [transactions,set_transactions] = useState([])
    const [processing_method,set_processing_method] = useState('normal process')
    const [payment_method,set_payment_method] = useState('foreign')
    const [phone,set_phone] = useState(null)
    const [re_render,set_re_render] = useState(null)

    useEffect(()=>{
        axios.get(window.server_url+'/transactions')
        .then(response=>set_transactions(response.data))
        .catch(err=>console.log(err))
    },[re_render])
    let amount;
    if(processing_method==='normal process'){
        if(payment_method==='indian')
            amount = '₹1500'
        else if(payment_method==='foreign')
            amount = '$50'
    }
    else if(processing_method==='fast track process'){
        if(payment_method==='indian')
            amount = '₹3000'
        else if(payment_method==='foreign')
            amount = '$100'
    }

    return(
        <div id='payments_container'>
            <Header/>
            <h1 className='has-text-centered' id ='payments_title'>Payments Section</h1>
            <section id='payment_form_cont'>
            <form onSubmit={e=>go_payment(e)} id='payment_form'>
                <div class="field">
                    <label class="label">First Name</label>
                    <div class="control has-icons-left">
                        <input required={true} id='first_name' class="input" type="text"/>
                        <span class="icon is-small is-left">
                            <FaUserAlt color='#168aad'/>
                        </span>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Email</label>
                    <div class="control has-icons-left">
                        <input required={true} id='email' class="input" type="email"/>
                        <span class="icon is-small is-left">
                            <IoMdMail color='#168aad'/>
                        </span>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Phone</label>
                    <div class="control">
                    <PhoneInput
                        country='in'
                        onChange={(value)=>set_phone(value)}
                        required = {true}
                    />    
                    </div>
                </div>
                <div class="field">
                    <label class="label">Processing Method</label>
                    <div class="control has-icons-left">
                        <div className="select">
                            <select required={true} onChange={()=>set_processing_method(document.getElementById('processing_method').value)} id="processing_method">
                                <option value="normal process">Normal Process</option>
                                <option value="fast track process">Fastrack Process</option>

                            </select>
                        </div>
                        <span class="icon is-small is-left">
                            <FaAffiliatetheme color='#168aad'/>
                        </span>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Payment Method</label>
                    <div class="control has-icons-left">
                        <div className="select">
                            <select required={true} onChange={()=>set_payment_method(document.getElementById('payment_method').value)} id="payment_method">
                                <option value="foreign">Paypal</option>
                                <option value="indian">Card/Wallet/UPI</option>

                            </select>
                        </div>
                        <span class="icon is-small is-left">
                            <BiMoney color='#168aad' size='20px'/>
                        </span>
                    </div>
                </div>
                <button id='paybtn' className="button is-mediu">Pay {amount}</button>
            </form>
            </section>
            <div id="payment_footer"></div>
            <Footer/>
        </div>
    )
    function random_txnid(length){
        let result = ''
        let characters = 'abcdefghijklmnopqrstuvxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        let no_of_chars = characters.length
        for(let i = 0;i<length;i++){
            result+=characters[Math.floor(Math.random()*no_of_chars)]
        }
        return result
    }
    function unique_txnid(length){
        let is_unique_txnid,txnid
        do{
            txnid = random_txnid(length)
            is_unique_txnid = true
            for (let i=0;i<transactions.length;i++){
                if(transactions[i].txnid===txnid){
                    is_unique_txnid=false
                    break
                }
            }

        }while(is_unique_txnid===false)
        return txnid;

    }
    function go_payment(e){
        e.preventDefault()

        let first_name = document.getElementById('first_name').value;
        let email = document.getElementById('email').value;
        let money = amount.substr(1);
        let processing_method = document.getElementById('processing_method').value;
        let payment_method = document.getElementById('payment_method').value;
        let txnid = unique_txnid(10);

        //posting to database
        let jsondata = {
            first_name:first_name,
            email:email,
            amount:amount,
            txnid:txnid,
            phone:phone
        }
        axios.post(window.server_url+'/transactions',jsondata)
        .then(response=>set_re_render(response.data))
        .catch(err=>console.log(err))
        // payU method
        if(payment_method==='indian'){
            let key = '6sGLuz9r'
            let salt = 'uEggQyYw7p'
            let payU_url = 'https://secure.payu.in/_payment'
            let hash;
            sha512(`${key}|${txnid}|${money}|${processing_method}|${first_name}|${email}|||||||||||${salt}`)
            .then(value=>{
                hash=value
                const form = document.createElement('FORM')
                form.method = 'POST'
                form.action = payU_url
                form.target = 'payU'
                
                const input1 = document.createElement('INPUT')
                input1.type='hidden'
                input1.name='key'
                input1.value=key

                const input2 = document.createElement('INPUT')
                input2.type='hidden'
                input2.name='productinfo'
                input2.value=processing_method

                const input3 = document.createElement('INPUT')
                input3.type='hidden'
                input3.name='amount'
                input3.value=money

                const input4 = document.createElement('INPUT')
                input4.type='hidden'
                input4.name='email'
                input4.value=email

                const input5 = document.createElement('INPUT')
                input5.type='hidden'
                input5.name='firstname'
                input5.value=first_name
    
                const input6 = document.createElement('INPUT')
                input6.type='hidden'
                input6.name='phone'
                input6.value=`${phone}`
    
                const input7 = document.createElement('INPUT')
                input7.type='hidden'
                input7.name='furl'
                input7.value="http://localhost:3000/for-editor"
    
                const input8 = document.createElement('INPUT')
                input8.type='hidden'
                input8.name='surl'
                input8.value="http://localhost:3000/open-access-policy"
    
                const input9 = document.createElement('INPUT')
                input9.type='hidden'
                input9.name='hash'
                input9.value=hash
    
                const input10 = document.createElement('INPUT')
                input10.type='hidden'
                input10.name='txnid'
                input10.value=txnid

                form.appendChild(input1)
                form.appendChild(input2)
                form.appendChild(input3)
                form.appendChild(input4)
                form.appendChild(input5)
                form.appendChild(input6)
                form.appendChild(input7)
                form.appendChild(input8)
                form.appendChild(input9)
                form.appendChild(input10)
    
                document.body.appendChild(form)
                window.open("",'payU')
                form.submit()
                form.remove()
            })  
        }
        //paypal payment
        else if(payment_method==='foreign'){
            if(processing_method==='normal process'){
                const form = document.createElement('FORM')
                form.method = 'POST'
                form.action = "https://www.paypal.com/cgi-bin/webscr"
                form.target = 'paypal'
                const input1 = document.createElement('INPUT')
                input1.type='hidden'
                input1.name = 'cmd'
                input1.value = '_s-xclick'

                const input2 = document.createElement('INPUT')
                input2.type='hidden'
                input2.name = 'hosted_button_id'
                input2.value='7XCG7LANYCDWQ'
                form.appendChild(input1)
                form.appendChild(input2)
                document.body.appendChild(form)
                window.open("",'paypal')
                form.submit()
                form.remove()
                
            }
            else if(processing_method==='fast track process'){
                const form = document.createElement('FORM')
                form.method = 'POST'
                form.action = "https://www.paypal.com/cgi-bin/webscr"
                form.target = 'paypal'
                const input1 = document.createElement('INPUT')
                input1.type='hidden'
                input1.name = 'cmd'
                input1.value = '_s-xclick'

                const input2 = document.createElement('INPUT')
                input2.type='hidden'
                input2.name = 'hosted_button_id'
                input2.value='WHVX995VGS7S2'
                form.appendChild(input1)
                form.appendChild(input2)
                document.body.appendChild(form)
                window.open("",'paypal')
                form.submit()
                form.remove()
                
            }
        }
    }
}
export default Payments;

function sha512(str) {
    return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
      return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    });
  }




