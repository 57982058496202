import Header from './front_page/header_front'
import Footer from './front_page/footer'

import axios from 'axios'
import Select from 'react-select'
import { useState,useEffect } from 'react'
import { Journal_list } from './front_page/Main'

const Privacy_policy = ()=>{
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    let repeated_index = []

    const [indexing_obj,set_indexing_obj] = useState([])
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_journal_obj(responses[1].data);
            set_indexing_obj(responses[2].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return(
        <div>
        <Header/>
        <div id='main_tile' className="is-ancestor is-parent tile">
            <div className='tile is-3 is-parent is-vertical'>
                <div id='main_first_tile_child' className='notification is-white is-child box'>
                    <h1>Open Access Journals</h1>
                    <Select id='main_Select' options={options} onChange={e=>{
                        if(e.value==='All')
                            set_filter_cat(e.value)
                        else{
                            for(let i =0;i<cat_array.length;i++){
                                if(e.value===cat_array[i].category){
                                    set_filter_cat(cat_array[i].id)
                                }
                            }
                        }
                    } 
                    }/>
                    {
                    // displaying journal names with icons in a loop gotten from the data
                        journal_obj.map(obj=>{
                            if(filter_cat==='All')
                                return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                            else if(filter_cat===obj.journal_cat_id)
                                return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                    })
                        
                }
            </div>
            <div id="indexing_container" className="is-child notification is-white box">
                {
                    indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                        let unique_url = true
                        if(index!==0){
                            for(let i = 0;i<repeated_index.length;i++){
                                if(repeated_index[i]===index_obj.url){
                                    unique_url = false
                                    break
                                }
                            }
                        }
                        if(unique_url){
                            repeated_index.push(index_obj.url)
                            return(
                                <a key={`${index} indexing`} href={index_obj.url}>
                                    <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                </a>
                            )
                        }
                    })
                }
            </div>
        </div>
            <div className="tile is-parent">
                <div id="plagiarism_container">
                    <div id="plagiarism_policy_container">
                        <h1>PRIVACY POLICY</h1>
                        <p className='text_justify' style={{fontSize:'1rem'}}>
                        This is the privacy policy for aipublisher.org which is run and provided by Jardyan OPC Private Limited. Jardyan OPC Private Limited is located at The House No.275, Uttar Ashinagar, Hojai, Assam, India. The company is committed to protecting your privacy and safeguarding your personal information.
                        </p>
                        
                        <p className='text_justify' style={{fontSize:'1rem'}}>This policy explains what information we collect, how we use it, who we share it with, and how you can view, modify, or delete your personal information on our website. Your personal information will be kept for as long as it is required to accomplish the purpose for which it was acquired, or for business or legal purposes, or as required by law.</p>
                        <h1>PERSONAL DATA COLLECTION, USE, AND DISCLOSURE</h1>
                        <p className='text_justify' style={{fontSize:'1rem'}}>
                        In general, we collect personal date or information when you visit our website, make online purchases through our website, download or use any of our contents on our website, create an account with us, or respond to any of our promotions or marketing activities.
                        </p>
                        <p className='text_justify' style={{fontSize:'1rem'}}>The collected private details is used to provide the services you requested through our website. We may require personal information from you in order to process your requests or respond to your inquiries quickly. Personal information includes, like your full name, identity card or passport details, telephone number, email address, residential address, institutional address, etc. </p>
                        <p className='text_justify' style={{fontSize:'1rem'}}>
                        By providing your personal data, you consent to the collection, use and disclosure of your personal information in the manner described in this policy. You must ensure that all personal information provided to us is true, complete and accurate. Failure to do so by you or the third party concerned may prevent us from providing the products and services you have requested.
                        </p>
                        <p className='text_justify' style={{fontSize:'1rem'}}>
                        From time to time, we may use your personal information to contact you for verification or marketing purposes. This can include email, SMS, and WhatsApp messages. We promise to only send you messages that we believe are relevant or of interest to you, such as promotions, specials and new product launches. If you do not wish to receive such messages, you may unsubscribe from receiving such marketing messages at any time by calling us, emailing us or if available and, if available, selecting contact unsubscribe link at the bottom of the message sent to you.
                        </p>
                        <p className='text_justify' style={{fontSize:'1rem'}}>
                        We do not sell your personal information to third parties. Where required by law, we may disclose your personal information to authorities upon request. 
                        </p>
                    
                    
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    
    )
}

export default Privacy_policy;