import Footer from "./front_page/footer";
import Header from "./front_page/header_front";
import { useState,useEffect } from "react";
import axios from "axios";
import '../css/join_as_editor.css'
import {FaModx,FaGetPocket,FaAffiliatetheme,FaUserAlt,FaJournalWhills} from 'react-icons/fa'
import {SiGmail} from 'react-icons/si'

const Join_as_editor = ()=>{
    const [journal_names,set_journal_names] = useState([])
    
    useEffect(()=>{
        axios.get(`${window.server_url}/journals/journal_name`)
        .then(response=>set_journal_names(response.data))
        .catch(err=>console.log(err))
    },[])
    return(
        <div>
            <button className='button is-success is-large' id="success_submit">Successfully Submitted</button>
            <Header/>
            <h3 className='has-text-centered is-size-3' style={{backgroundColor:'#99d98c',color:'white',padding:'5px 0px'}}>Join As Editor</h3>
            <form onSubmit={(e)=>submit_form(e)} id='join_as_editor_cont' className="columns">
                {/* left form part */}
                <div  className="text_justify column is-half">
                    <div className="field">
                        <label className="label">First Name</label>
                        <div className="control has-icons-left">
                            <input required={true} type="text" name="" id="first_name" className="input" />
                            <span className="icon is-small is-left">
                                <FaUserAlt color='#99d98c'/>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Last Name</label>
                        <div className="control has-icons-left">
                            <input required={true} type="text" name="" id="last_name" className="input" />
                            <span className="icon is-small is-left">
                                <FaUserAlt color='#99d98c'/>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Email</label>
                        <div className="control has-icons-left">
                            <input required={true} type="email" name="" id="email" className="input" />
                            <span className="icon is-small is-left">
                                <SiGmail color='#e63946'/>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Select Journal</label>
                        <div className="control has-icons-left">
                            <div className="select">
                                <select required={true} name="" id="journal_name">
                                    {
                                        journal_names.map((obj,index)=>(
                                            <option key={`journal_name ${index}`} value={obj.journal_name}>{obj.journal_name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <span className="icon is-small is-left">
                                <FaJournalWhills color='green'/>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="" className="label">Affiliation</label>
                        <div className="control has-icons-left">
                            <input type="text" name="" id="affiliation" className="input" />
                            <span className="icon is-small is-left">
                                <FaAffiliatetheme color='#184e77'/>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="" className="label">Please provide a link to your institutional profile page</label>
                        <div className="control has-icons-left">
                            <input type="url" name="" id="institutional_profile" className="input" />
                            <span className="icon is-small is-left">
                                <FaGetPocket color='#fb8500'/>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="" className="label">Please provide a link to your publication record (eg: Scopus,PubMed etc...)</label>
                        <div className="control has-icons-left">
                            <input type="url" name="" id="publication_record" className="input" />
                            <span className="icon is-small is-left">
                                <FaModx color='#e5383b'/>
                            </span>
                        </div>
                    </div>
                </div>
                {/* right form part */}
                <div  className="text_justify column is-half">
                    <div className="field">
                        <label htmlFor="" className="label">Have you ever interacted with any A.I.P journal before?</label>
                        <div className="control">
                            <label className="radio">
                                <input id='is_aip_interacted' type="radio" name="answer"/>
                                Yes
                            </label>
                            <label className="radio">
                                <input type="radio" name="answer"/>
                                No
                            </label>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="" className="label">Please give a brief description of your area(s) of expertise (3-5 sentences)</label>
                        <div className="control">
                            <textarea rows='6' id='aoe' className="textarea is-link"></textarea>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="" className="label">Please describe why you are interested in this role (3-5 sentences)</label>
                        <div className="control">
                            <textarea rows='6' id='why_interested' className="textarea is-link"></textarea>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="" className="label">Upload your C.V</label>
                        <div className="control">
                            <input required={true} type="file" name="" id="editor_cv" className="input" />
                            
                        </div>
                    </div>
                    <button className="button is-success is-medium">Submit</button>

                
                </div>
                
            </form>
            <Footer/>
        </div>
    )
}

function submit_form(e){
    e.preventDefault()
    window.show_loader()
    const first_name = document.getElementById('first_name').value;
    const last_name = document.getElementById('last_name').value;
    const email = document.getElementById('email').value;
    const journal_name = document.getElementById('journal_name').value;
    const affiliation = document.getElementById('affiliation').value;
    const institutional_profile = document.getElementById('institutional_profile').value;
    const publication_record = document.getElementById('publication_record').value;
    const is_aip_interacted = document.getElementById('is_aip_interacted').checked;
    const aoe = document.getElementById('aoe').value;
    const why_interested = document.getElementById('why_interested').value;
    let editor_cv = document.getElementById('editor_cv').files[0]
    const file_name = `${email}-${editor_cv.name}`;
    let formdata = new FormData();
    formdata.append("first_name",first_name)
    formdata.append("last_name",last_name)
    formdata.append("email",email)
    formdata.append("journal_name",journal_name)
    formdata.append("affiliation",affiliation)
    formdata.append("institutional_profile",institutional_profile)
    formdata.append("publication_record",publication_record)
    formdata.append("is_aip_interacted",is_aip_interacted)
    formdata.append("aoe",aoe)
    formdata.append("why_interested",why_interested)
    formdata.append("file_name",file_name)
    formdata.append("editor_cv",editor_cv,file_name)    
    axios.post(window.server_url+'/join_as_editor',formdata)
    .then(()=>{
        //clear values and show success button

        window.hide_loader()
        document.getElementById('success_submit').style.zIndex=100
        setTimeout(()=>document.getElementById('success_submit').style.zIndex=-100,3200)
        document.getElementById('first_name').value=""
        document.getElementById('last_name').value=""
        document.getElementById('email').value=""
        document.getElementById('journal_name').value=""
        document.getElementById('affiliation').value=""
        document.getElementById('institutional_profile').value=""
        document.getElementById('publication_record').value=""
        document.getElementById('aoe').value=""
        document.getElementById('why_interested').value=""
        document.getElementById('editor_cv').value=""
    })
    .catch(err=>console.log(err))
}

export default Join_as_editor;



