import Header from './front_page/header_front';
import Footer from './front_page/footer';
import parse from 'html-react-parser';
import { useState,useEffect } from 'react';
import axios from 'axios';


const Books_pages = ()=>{
    let url_split = window.location.href.split('/')
    let url_focused_element = url_split[url_split.length-1]
    let book_page_title = url_focused_element.replaceAll('-',' ')
    const [page_content,set_page_content] = useState([])


    useEffect(()=>{
        axios.get(window.server_url+'/books_pages_content/'+book_page_title)
        .then(response=>{
            set_page_content(response.data)
        })
        .catch(err=>console.log(err))
    },[])
    return(
        <section style={{backgroundColor:'white'}} id='books_pages_container'>
            <Header/>
            <div style={{minHeight:'150px'}}>
                {page_content.length&&parse(page_content[0].page_content)}

            </div>
            <Footer/>
        </section>
    )
}

export default Books_pages;