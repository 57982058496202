import {useState,useEffect} from 'react'
import axios from 'axios'
import '../css/books_preview.css'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from 'react-responsive-carousel'
import Header from './front_page/header_front'
import Footer from './front_page/footer'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import parse from 'html-react-parser';
import cookies from 'js-cookie'
import { Rating } from 'react-simple-star-rating'

const Books_preview = ({id})=>{
    const [book,set_book] = useState([])
    const [review_render,set_review_render] = useState([])
    const [review_obj,set_review_obj] = useState([])
    useEffect(()=>{
        window.show_loader()
        axios.get(window.server_url+'/books/'+id)
        .then(response=>{
            set_book(response.data)
            window.hide_loader()
        })
    },[])
    useEffect(()=>{
        axios.get(window.server_url+'/books_review/'+id)
        .then(response=>{
            set_review_obj(response.data)
            window.hide_loader()
        })
        .catch(err=>console.log(err))
    },[review_render])
    const [rating, setRating] = useState(0) // initial rating value

  // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate)
    }
    function post_review(e){
        e.preventDefault()
        window.show_loader()
        const review_name = document.getElementById('review_name').value 
        const review_review = document.getElementById('review_review').value 
        const stars = rating

        const json_obj = {
            review_name,
            review_review,
            stars,
            book_id:id
        }
        axios.post(window.server_url+'/books_review',json_obj)
        .then(response=>{
            cookies.set('reviews'+id,true)
            set_review_render(response.data)
            let success_btn = document.getElementById('success_submit');
            success_btn.style.zIndex=100
            setTimeout(()=>success_btn.style.zIndex=-100,2000)
        })
        .catch(err=>console.log(err))

    }
    return(
        <div id='books_preview_container'>
            <div id={`success_submit`} className="button is-large is-success">Successfully Uploaded!!</div>

            <Header/>
            <div className="m-0 columns notification is-white">
                <div className="column is-3">
                    {
                        book.length&&
                        <Carousel
                            showThumbs={true}
                            showIndicators={false}

>
                            <div>
                                <img src={`/resources/books_resources/${book[0].primary_image_name}`} alt="" />
                            </div>
                            <div>
                                <img src={`/resources/books_resources/${book[0].secondary_image_name}`} alt="" />
                            </div>
                    </Carousel>
                    }
                    
                </div>
                <div className="column">
                    <h1 className='is-size-4 has-text-weight-bold' id="book_title">{book.length&&book[0].title}</h1>
                    <h5 className=''><span style={{color:'grey'}}>AUTHOR: </span>{book.length&&book[0].author}</h5>
                    <h5 className='mt-1 has-text-info has-text-weight-bold is-size-4'>{book.length&&(book[0].price===0?"Free":'$'+book[0].price)}</h5>
                    <h6 className='mt-3'>{book.length&&book[0].summary}</h6>
                    <div style={{borderBottom:'solid 1px black'}}>
                        {
                            book.length&&book[0].price===0?<Link style={{textDecoration:'none'}} target='_blank' download to={`/resources/books_resources/${book[0].book_pdf_name}`}><button className='my-5 button is-medium is-success'>Download</button></Link>:<button className='my-5 is-medium is-info button'>Add to cart</button>
                        }
                    </div>
                </div>
            </div>
            <div className="notification is-white">
            <Tabs>
              <TabList>
                <Tab>Description</Tab>
                <Tab>Additional Information</Tab>
                <Tab>Review</Tab>

              </TabList>

              <TabPanel>
                <h2>{book.length&&parse(book[0].description)}</h2>
              </TabPanel>
              <TabPanel>
                <h2>{book.length&&parse(book[0].additional_information)}</h2>
              </TabPanel>
              <TabPanel>
                  <div>
                        {
                            review_obj.map((obj,index)=>{
                                    return(
                                    <div>
                                        <h1 className='is-size-5 has-text-weight-bold'>{obj.review_name}</h1>
                                        <h2 className='my-3'>{obj.review_review}</h2>
                                        <Rating readonly={true} ratingValue={obj.stars} />
                                    </div>
                                    )
                                
                            })
                        }
                        {
                            !cookies.get('reviews'+id)&&<form onSubmit={(e)=>post_review(e)}>
                            <input type="text" placeholder="Your name" className='input' id="review_name" />
                            <textarea className="mt-3 mb-5 textarea" placeholder="Write a review"  id="review_review" cols="30" rows="10"></textarea>
                            <Rating onClick={handleRating} ratingValue={rating} /* Available Props */ /><br/>
                            <button className="mt-3 button is-success">Post Review</button>
                        </form>
                        }
                  </div>
              </TabPanel>
            </Tabs>
            </div>
            
            <Footer/>
        </div>
    )
}

export default Books_preview