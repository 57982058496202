import Header from "./front_page/header_front"
import Footer from "./front_page/footer"
import axios from 'axios'
import Select from 'react-select'
import { useState,useEffect } from 'react'
import { Journal_list } from './front_page/Main'
import {FaTwitter,FaFacebookF,FaLinkedinIn} from 'react-icons/fa'
import '../css/contact_us.css'

const Contact_us = ()=>{
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    let repeated_index = []

    const [indexing_obj,set_indexing_obj] = useState([])
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_journal_obj(responses[1].data);
            set_indexing_obj(responses[2].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return(
        <div>
            <Header/>
            <div id='main_tile' className="is-ancestor is-parent tile">
                <div className='tile is-3 is-parent is-vertical'>
                    <div id='main_first_tile_child' className='notification is-white is-child box'>
                        <h1>Open Access Journals</h1>
                        <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                        }/>
                        {
                        // displaying journal names with icons in a loop gotten from the data
                            journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                        })
                            
                    }
                </div>
                <div id="indexing_container" className="is-child notification is-white box">
                    {
                        indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                            let unique_url = true
                            if(index!==0){
                                for(let i = 0;i<repeated_index.length;i++){
                                    if(repeated_index[i]===index_obj.url){
                                        unique_url = false
                                        break
                                    }
                                }
                            }
                            if(unique_url){
                                repeated_index.push(index_obj.url)
                                return(
                                    <a key={`${index} indexing`} href={index_obj.url}>
                                        <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
                <div className="tile is-parent">
                    <div id="plagiarism_container">
                        <div id="plagiarism_policy_container">
                            <h1 style={{textDecoration:'underline'}}>Contact Us</h1>
                            <p className='text_justify'>
                                Addaiyan International Publisher
                                <br />
                                Address: House No.275, Uttar Ashinagar,Hojai,Assam(India) Post Box No.782445
                                <br />
                                E-mail: support@aipublisher.org
                            </p>
                            <h1>Feedback</h1>
                            <p className='text_justify'>
                                We are constantly working on improving our platform and our publishing processing the aim
                                of providing you with the most excellent scientific publishing experience. Therefore, we value of your opinion
                                and suggestions. Contact us at feedbackaipublisher@gmail.com
                            </p>
                            <h1>Social Networking</h1>
                            <p id='contact_us1'>
                                <ul>
                                    <li>
                                        <a href="https://twitter.com/aipublisher">
                                            <FaTwitter color='#1c9ceb'/> 
                                            <h2>
                                                https://twitter.com/aipublisher
                                            </h2>
                                            
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://in.linkedin.com/in/addaiyan-international-664514191/?originalSubdomain=in">
                                            <FaLinkedinIn color='#0073b1'/> 
                                            <h2>
                                                https://in.linkedin.com/aipublisher
                                                {/* https://in.linkedin.com/in/addaiyan-international-664514191/?originalSubdomain=in */}
                                            </h2>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://facebook.com/aipublisher">
                                            <FaFacebookF color='#38529a'/> 
                                            <h2>
                                                https://facebook.com/aipublisher
                                            </h2>
                                        </a>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact_us;





