import Header from './header_front'
import Footer from './footer'
import Search from './search'
import {Article_child} from './Main'
import axios from 'axios';
import { useEffect,useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import '../../css/front_page/search_result.css'

let loading_finished = false;
const Search_result = ()=>{
    const search_parts = window.location.search.split('&');
    const title = search_parts[0].substr(7);
    const author = search_parts[1].substr(7);
    const journal_id = search_parts[2].substr(11);
    
    const [article_obj,set_article_obj] = useState([]);
    const [selected_item,set_selected_item] = useState(null);
    const [slider_name_array,set_slider_name_array] = useState([]);
    useEffect(()=>{
    window.show_loader()

        axios.post(`${window.server_url}/search`,{title:title,author:author,journal_id:journal_id})
        .then(response=>{
            loading_finished = true
            set_article_obj(response.data) 
            window.hide_loader()
               
        })
        .catch(error=>console.log('dedadoo',error))
    },[])
    if(!article_obj.length && loading_finished){
        return(
            <section className='search_result' style={{backgroundColor:'white'}}>
                <Header/>
                <div className='thinline'></div>
                <Search btn_color='#4f5671' menu_color='white'/>
                <div className="thinline"></div>
                <h6 className='search_result_result'>No Results Found!</h6>
                <Footer/>
            </section>
        )
    }
    console.log('search',article_obj)
    return(
        <section style={{backgroundColor:'white'}} className="search_result">
            <Header/>
            <div className="thinline"></div>
            <Search btn_color='#4f5671' menu_color='white'/>
            <div className="thinline"></div>
            <h6 className='search_result_result'>Query Results Found!</h6>
            {/* big screen slider show for article */}
            <div id='big_screen_carrow'>
                <div id="big_screen_carrow_container">
                    <div onClick={()=>{
                        document.getElementById('big_screen_carrow').style.display='none';
                    }} id="main_close_btn">
                        <AiOutlineCloseCircle size={'40px'} color={'white'}/>
                    </div>
                    <Carousel selectedItem={selected_item} showIndicators={false} showThumbs={false}>
                        {
                            slider_name_array.map((slider_name,index)=>(
                                <div key={`main ${index}`}>
                                    <div className='big_img_article'>
                                        <img src={`/resources/article_slides/${slider_name}`}/>
                                    </div>
                                    <p style={{color:'white',fontSize:'1.3rem',fontWeight:'400',marginTop:'20px'}}>{`Figure ${index+1}`}</p>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
            {
                article_obj.map(article_obj=>{
                    
                    let slider_urls=[];
                    for(let i =0;i<article_obj.slider_count;i++){
                        slider_urls.push(`${article_obj.doi_number}.${i}.png`)
                    }
                    return(
                        <Article_child
                        key={`${article_obj.doi_number}`}
                        title = {article_obj.article_title}
                        authors = {article_obj.author_name}
                        date = {article_obj.article_date.substr(0,10)}
                        abstract = {convertToPlain(article_obj.abstract_content)}
                        slider_name_array={slider_urls}
                        set_selected_item = {set_selected_item}
                        set_slider_name_array={set_slider_name_array}
                        doi_number ={article_obj.doi_number}
                        article_file_name={article_obj.article_file_name}
                        // article_child_style={{marginLeft:'45px',marginTop:'30px',marginBottom:'30px',marginRight:'30px'}}
                        download_icon_size='25px'
                        volume = {article_obj.volume}
                        issue={article_obj.issue}
                        id={article_obj.id}
                        page_no={article_obj.page_no}
                        article_abbrevation={article_obj.article_abbrevation}
                        />
                    )
                  
                })
            }
            <Footer/>
            
        </section>
    )
}

export default Search_result;

function convertToPlain(html){

    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}