import Header from './front_page/header_front'
import Footer from './front_page/footer'
import axios from 'axios'
import {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'

const Books_search = ()=>{
    const book_search = window.location.search.substr(7)
    const [book,set_book] = useState([])

    useEffect(()=>{
        window.show_loader()
        axios.get(window.server_url+'/books_search/'+book_search)
        .then(response=>{
            set_book(response.data)
            window.hide_loader()
        })
        .catch(err=>console.log(err))
    },[])
    return(
        <div style={{backgroundColor:'white'}} id='books_search_container'>
            <Header/>
            <div style={{minHeight:'20vh'}}>
            <h1 style={{textDecoration:'underline'}} className='mb-5 has-text-centered is-size-3 has-text-underline'>{book.length?"Results Found:":"No Results Found:"}</h1>
            <div className="mx-4 columns is-multiline">
                {
                    book.map(obj=>{
                            return(
                                <div className='column is-one-quarter'>
                                <Link to={`/book/${obj.title.replaceAll(' ','-')}`}>

                                    <img style={{height:'250px'}} className='image' src={`/resources/books_resources/${obj.primary_image_name}`} />
                                    <h2 className='mr-6'>{obj.title}</h2>
                                    <h2 className='has-text-success is-size-5'>${obj.price}</h2>
                                </Link>

                                </div>
                            )
                    }
                        
                    )
                }
            </div>
            </div>
            
            <Footer/>
        </div>
    )
}

export default Books_search