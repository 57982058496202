import JoditEditor from 'jodit-react';
import '../../../css/back_office/article_back.css'
import axios from 'axios';
import {BiAddToQueue} from 'react-icons/bi';
import {AiFillDelete} from 'react-icons/ai';
import { useState,useEffect } from 'react';

const Back_article_edit = ()=>{
    const referrer = document.referrer;
    const config = {
        uploader:{
            "insertImageAsBase64URI": true
        },
        toolbarAdaptive:false
    }
    const abstract_config = {
        toolbarAdaptive:false
    }
    const [journal_name_and_ids,set_journal_name_and_ids] = useState([]);    
    const article_id = window.location.search.substr(4);

    const [article_obj,set_article_obj] = useState([]);
    const [update_article,set_update_article] = useState(null);
    let default_journal_name;
    useEffect(()=>{
    window.show_loader();

        axios.all([
            axios.get(`${window.server_url}/journals/journal_name_and_id`),
            axios.get(`${window.server_url}/article_db/match${article_id}`)
        ])
        .then(axios.spread((...responses)=>{
            set_journal_name_and_ids(responses[0].data);
            set_article_obj(responses[1].data);

            //getting default journal_name
            for (let i =0;i<journal_name_and_ids.length;i++){
                if(journal_name_and_ids[i].id==article_obj[0].journal_id){
                    default_journal_name = journal_name_and_ids[i].journal_name;
                }
            }
            window.hide_loader();
        }))
        .catch(err=>console.log(err))
    },[update_article])

    return(
        <div id='article_back_container'>
            <div className="button is-large is-success" id="success_submit">Successfully Uploaded</div>
            <h1>Edit Article</h1>
            
                {
                    article_obj.map(obj=>{
                        html_content=obj.html_content;
                        abstract_content=obj.abstract_content;
                        return(
                            <form key="formi" onSubmit={(e)=>submit_form(set_update_article,e,article_id,obj.article_file_name,obj.slider_count,journal_name_and_ids)}>
                            <label htmlFor='art_year'>Year</label>
                            <input className='input' type="number" defaultValue={obj.year} name="" id="art_year"/>
                            <label htmlFor="art_month">Month</label>
                            <input defaultValue={obj.month} className='input' type="text" name="" id="art_month" />
                            <label htmlFor="art_volume">Volume</label>
                            <input defaultValue={obj.volume} className='input' type="text" name="" id="art_volume" />
                            <label htmlFor="art_issue">Issue</label>
                            <input defaultValue={obj.issue} className='input' type="text" name="" id="art_issue" />
                            <label htmlFor="art_title">Article Title</label>
                            <input defaultValue={obj.article_title} className='input' type="text" name="" id="art_title" />
                            <label htmlFor="art_author_name">Author Name</label>
                            <input defaultValue={obj.author_name} className='input' type="text" name="" id="art_author_name" />
                            <label htmlFor="art_journo_name">Journal Name</label>
                            <select defaultValue={default_journal_name} className='input' id="art_journo_name">
                                {
                                    journal_name_and_ids.map(journal_obj=>(
                                        <option key={`${journal_obj.journal_name}`} value={`${journal_obj.journal_name}`}>{`${journal_obj.journal_name}`}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="">Date of Issue</label>
                            <input defaultValue={obj.article_date.substr(0,10)} className='input' type="date" name="" id="art_date" />
                            <label htmlFor="">Page No.</label>
                            <input defaultValue={obj.page_no} type="text" name="" id="page_no" className="input" />
                            <label htmlFor="">Abbrevation</label>
                            <input defaultValue={obj.article_abbrevation} type="text" name="" id="article_abbrevation" className="input" />
                            
                            <label htmlFor="">Abstract</label>
                            <JoditEditor
                                config={abstract_config}
                                onChange = {e=>abstract_content=e}
                                value={obj.abstract_content}
                            />
                            <label htmlFor="art_doi">DOI Number</label>
                            <input defaultValue={obj.doi_number} className='input' type="text" name="" id="art_doi" />
                            <label htmlFor="">Upload HTML CODE</label>
                            <JoditEditor
                                config={config}
                                onChange={e=>html_content=e}
                                value={obj.html_content}
                            />
                            <label htmlFor="">Upload pdf of the File</label>
                            <input className='input' type="file" name="" id="art_file" />
                            <h3 id="incorrect_extension">Extension is not Supported!</h3>
                            <section>
                                <div id="art_add_cont">
                                    <h2>Upload carousel Images seperately</h2>
                                    <button type='button' onClick={()=>add_fileinput()}>
                                        <BiAddToQueue size='40px' color='#90e0ef'/>
                                    </button>
                                    <button type='button' onClick={()=>delete_fileinput()}>
                                        <AiFillDelete size='40px' color='#ef233c'/>
                                    </button>
                                </div>

                                <div id="art_multiple_input_cont">
                                    <span>
                                        <label htmlFor="">Image 1</label>
                                        <input className='art_slider' type="file" name="" id="" />
                                        <h3 className="art_slider_err_msg">Please upload Image files (png,jpg,jpeg...)</h3>
                                    </span>

                                </div>
                            
                            </section>
                            <input className='button is-danger mt-3' type="submit" value="Submit" />
                            </form>
                        )
                    })
                }
         
        </div>
    )
}
let file_counter = 1;
let abstract_content,html_content;
function add_fileinput(){
    let input_container = document.getElementById('art_multiple_input_cont');
    input_container.innerHTML+=`<span><label>Image ${++file_counter}</label> <input class='art_slider' type="file"/><h3 class="art_slider_err_msg">Please upload Image files (png,jpg,jpeg...)</h3></span>`;

}
function delete_fileinput(){
    let input_container = document.getElementById('art_multiple_input_cont');
    if(input_container.children.length>1){
        input_container.removeChild(input_container.lastChild);
        file_counter--;
    }
}
const submit_form = (set_update_article,e,article_id,article_file_name,old_slider_counter,journal_name_and_ids)=>{
    let validation = true;
    e.preventDefault();
    const year = document.getElementById('art_year').value;
    const month = document.getElementById('art_month').value;
    const volume = document.getElementById('art_volume').value;
    const issue = document.getElementById('art_issue').value;
    const article_title = document.getElementById('art_title').value;
    const author_name = document.getElementById('art_author_name').value;
    const journal_name = document.getElementById('art_journo_name').value;
    const page_no = document.getElementById('page_no').value;
    const article_abbrevation = document.getElementById('article_abbrevation').value;
    //setting journal id
    let journal_id;
    for (let i =0;i<journal_name_and_ids.length;i++){
        if(journal_name_and_ids[i].journal_name==journal_name){
            journal_id = journal_name_and_ids[i].id;
        }
    }
    const date = document.getElementById('art_date').value;
    const doi = document.getElementById('art_doi').value;
    const art_file = document.getElementById('art_file').files[0];
    
    let art_file_extension;
    //validating
    //validation of article file
    if(art_file!==undefined){
        let art_file_dot_array = art_file.name.split('.');
        art_file_extension = art_file_dot_array[art_file_dot_array.length-1];
        if(art_file_extension!=='doc' && art_file_extension!=='docm' && art_file_extension!=='docx' && art_file_extension!=='dotm' && art_file_extension!=='dotx' && art_file_extension!=='odt' && art_file_extension!=='pdf' && art_file_extension!=='xml'){
            validation=false;
            document.getElementById('incorrect_extension').style.display='block';
        }
        else
            document.getElementById('incorrect_extension').style.display='none';
    }
    else{
        document.getElementById('incorrect_extension').style.display='none'
    }

    //validation of article_slides
    let slider_inputs = document.getElementsByClassName('art_slider');
    let slider_inputs_err = document.getElementsByClassName('art_slider_err_msg');
    for(let i =0;i<slider_inputs.length;i++){
        let input_file = slider_inputs[i].files[0];
        if(input_file!==undefined && input_file.type.split('/')[0]!=='image'){
            validation=false;
            slider_inputs_err[i].style.display='block';
        }
        else
            slider_inputs_err[i].style.display='none';
    }
    if(validation){
        window.show_loader();
        let formdata = new FormData();
        formdata.append('page_no',page_no)
        formdata.append('article_abbrevation',article_abbrevation)
        formdata.append('year',year);
        formdata.append('month',month);
        formdata.append('volume',volume);
        formdata.append('issue',issue);
        formdata.append('article_title',article_title);
        formdata.append('author_name',author_name);
        // formdata.append('journal_name_and_id',journal_name_and_id);
        formdata.append('journal_id',journal_id);
        formdata.append('date',date);
        formdata.append('doi',doi);
        if(art_file!==undefined){
            formdata.append('article_resources',art_file,`article_doc${doi.replace(/\//g,'-')}.${art_file_extension}`);
            formdata.append('article_file_name',`article_doc${doi.replace(/\//g,'-')}.${art_file_extension}`)
        }
        else
            formdata.append('article_file_name',article_file_name);
        formdata.append('abstract_content',abstract_content);
        
        //sending html_content seperately as json
        // formdata.append('html_content',html_content);
        
        //appending article slides
        //copied the exact code from article slider checking
        let new_sliders = false;
        let slider_counter = 0;
        let slider_inputs = document.getElementsByClassName('art_slider');
        for(let i =0;i<slider_inputs.length;i++){
            let input_file = slider_inputs[i].files[0];
            if(input_file!==undefined){
                //this is the new one
                new_sliders = true;
                formdata.append('article_resources',input_file,`${doi}.${slider_counter}.png`);

                slider_counter++;
            }
        }
        if(!new_sliders)
            formdata.append('slider_count',old_slider_counter);
        else
            formdata.append('slider_count',slider_counter);
        axios.put(`${window.server_url}/article_upload/${article_id}`,formdata)
        .then(()=>{
            axios.put(`${window.server_url}/html_content/${article_id}`,{html_content:html_content})
            .then(response=>{
                set_update_article(response.data)
                document.getElementById('success_submit').style.zIndex=100
                setTimeout(()=>document.getElementById('success_submit').style.zIndex=-100,3200)
            })
            .catch(err=>console.log(err))
        })
        .catch(eror=>console.log(eror))
        
    }
}
export default Back_article_edit;