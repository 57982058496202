import {useLocation} from "react-router-dom"
import '../../../css/manage_pages.css'
import JoditEditor from "jodit-react";
import axios from 'axios'
import { useState,useEffect } from "react";
import {AiFillDelete,AiFillEdit} from 'react-icons/ai';


const Manage_pages = ()=>{
    const search = useLocation().search;
    const journal_id = new URLSearchParams(search).get('journal_id')
    const [journal_page_obj,set_journal_page_obj] = useState([])
    const [journal_update,set_journal_update] = useState()
    const [edit_page_id,set_edit_page_id] = useState(null)    
    const [is_visible_edit_page,set_is_visible_edit_page] = useState('modal')
    const [edit_page_obj,set_edit_page_obj] = useState([])
    
    
    useEffect(()=>{
        window.show_loader()
        axios.get(window.server_url+'/journal_page/'+journal_id)
        .then(response=>{
            set_journal_page_obj(response.data)
            window.hide_loader()
        })

    },[journal_update])
    

    let page_content_add,page_content_edit;
    const config = {
        uploader:{
            "insertImageAsBase64URI": true
        },
        toolbarAdaptive:false,
        toolbarSticky:false
        // toolbarStickyOffset:200
    }
    const AddJournalPage = ({})=>{
        return(
            <div id='add_journal_page_container' class="modal">
                <div class="modal-background"></div>
                <div id='modal_card' class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Add Journal Page !</p>
                        <button onClick={()=>{
                            document.getElementById('page_title').value="";
                            document.getElementById('external_website').checked=0;
                            page_content_add=""
                            set_journal_update(Math.floor(Math.random()*100))
                            document.getElementById('add_journal_page_container').classList.remove('is-active')
                        }} class="delete" aria-label="close"></button>
                    </header>
                    <section class="modal-card-body">
                        {/* <!-- Content ... --> */}
                        <form className='page_form'>
                            <label htmlFor="">Page Title/URL</label>
                            <input type="text" className='input' id="page_title" />
                            <label htmlFor="">Page Content</label>
                            <JoditEditor
                                config={config}
                                onChange={e=>page_content_add=e}
                                value={page_content_add}
                
                            />
                            <span>External website ? &nbsp;&nbsp;&nbsp; <input type="checkbox" id="external_website"/></span>

                        </form>
                    </section>
                    <footer class="modal-card-foot">
                            <button onClick={(e)=>addPage(e)} class="button is-success">Save changes</button>
                            <button onClick={()=>{
                                document.getElementById('page_title').value="";
                                document.getElementById('external_website').checked=0;
                                page_content_add=""
                                set_journal_update(Math.floor(Math.random()*100))

                                document.getElementById('add_journal_page_container').classList.remove('is-active')
                        }}  class="button">Cancel</button>
                    </footer>
                </div>
            </div>
          
        )
        function addPage(e){
            e.preventDefault()
            window.show_loader()
            let page_title = document.getElementById('page_title').value;
            let is_external_website = document.getElementById('external_website').checked;

            let page_data = {
                page_title:page_title,
                is_external_website:is_external_website,
                page_content:page_content_add,
                journal_id:journal_id
            }

            axios.post(`${window.server_url}/journal_page`,page_data)
            .then(response=>{
                set_journal_update(response.data)

                let success_btn = document.getElementById('success_submit');
                success_btn.style.zIndex=100
                setTimeout(()=>success_btn.style.zIndex=-100,3200)


            })
            .catch(err=>console.log(err))


        }

    }

    const EditJournalPage = ({id})=>{
        let edit_page_title,edit_is_external_website;

        for(let i = 0;i<journal_page_obj.length;i++){
            if(journal_page_obj[i].id===id){
                edit_page_title= journal_page_obj[i].page_title;
                page_content_edit=journal_page_obj[i].page_content;
                edit_is_external_website=journal_page_obj[i].is_external_website;

            }
        }
        console.log(journal_page_obj)
        return(
            <div id='edit_journal_page_container' class={is_visible_edit_page}>
                <div class="modal-background"></div>
                <div id='modal_card' class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Edit Journal Page !</p>
                        <button onClick={()=>{
                            set_is_visible_edit_page('modal')
                        }} class="delete" aria-label="close"></button>
                    </header>
                    <section class="modal-card-body">
                        {/* <!-- Content ... --> */}
                        <form className='page_form'>
                            <label htmlFor="">Page Title/URL</label>
                            <input defaultValue={edit_page_title}  type="text" className='input' id="page_title_edit" />
                            <label htmlFor="">Page Content</label>
                            <JoditEditor
                                config={config}
                                onChange={e=>page_content_edit=e}
                                value={page_content_edit}
                
                            />
                            <span>External website ? &nbsp;&nbsp;&nbsp; <input defaultChecked={edit_is_external_website} type="checkbox" id="external_website_edit"/></span>

                        </form>
                    </section>
                    <footer class="modal-card-foot">
                            <button onClick={(e)=>editPage(e)} class="button is-success">Save changes</button>

                            <button onClick={()=>{
                                set_is_visible_edit_page('modal')
                                
                        }}  class="button">Cancel</button>
                    </footer>
                </div>
            </div>
          
        )
        function editPage(e){
            e.preventDefault()
            window.show_loader()
            let page_title = document.getElementById('page_title_edit').value
            let external_website = document.getElementById('external_website_edit').checked

            let page_json = {
                page_title:page_title,
                external_website:external_website,
                page_content:page_content_edit
            }

            axios.put(window.server_url+'/journal_page/'+id,page_json)
            .then(response=>{
                set_journal_update(response.data)

                let success_btn = document.getElementById('success_submit');
                success_btn.style.zIndex=100
                setTimeout(()=>success_btn.style.zIndex=-100,3200)


            })
            .catch(err=>console.log(err))
        }

    }

    function deletePage(id){
        if(window.confirm('Are you sure you want to delete this Page?')){
            window.show_loader()
            axios.delete(window.server_url+'/journal_page/'+id)
            .then(response=>{
                set_journal_update(response.data)
            })
            .catch(err=>console.log(err))
        }
 
    }
    return(
        <div id='manage_pages'>
            <div id={`success_submit`} className="button is-large is-success">Successfully Uploaded!!</div>

            <button onClick={()=>{
                document.getElementById('add_journal_page_container').classList.add('is-active')
            }} id="add_new_journal_page" className='button is-warning'>
                Add New Journal Page
            </button>
            <table className="back_journal_table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Title/Url</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        journal_page_obj.map(obj=>(
                            <tr key={obj.id}>
                                <td>{obj.id}</td>
                                <td>{obj.page_title}</td>
                                <td>
                                    <button onClick={()=>{
                                        set_edit_page_id(obj.id)
                                        set_is_visible_edit_page('modal is-active')
                                        }} className="button is-info">
                                        <AiFillEdit/>
                                    </button>
                                    <button onClick={()=>deletePage(obj.id)} className="button is-danger">
                                        <AiFillDelete/>
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            
            <AddJournalPage/>
            <EditJournalPage
                id={edit_page_id}
           
            />

        </div>
    )

}

export default Manage_pages;