import Header from './front_page/header_front';
import Footer from './front_page/footer';
import '../css/books.css'
import { useState,useEffect } from 'react';
import axios from 'axios'
import parse from 'html-react-parser';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';

const Books = ()=>{
    const [books_main_obj,set_books_main_obj] = useState([])
    const [books_pages,set_books_pages] = useState([])
    const [books_obj,set_books_obj] = useState([])
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/books_main'),
            axios.get(window.server_url+'/books_pages_titles'),
            axios.get(window.server_url+'/books/intro')


        ])
        .then(axios.spread((...responses)=>{
            set_books_main_obj(responses[0].data)
            set_books_pages(responses[1].data)
            set_books_obj(responses[2].data)
            window.hide_loader()
        }))


    },[])
    function submit_newsletter(e){
        e.preventDefault()
        let newsletter_email = document.getElementById('newsletter_email').value
        let newsletter_name = document.getElementById('newsletter_name').value
    
        let jsondata = {
            newsletter_email:newsletter_email,
            newsletter_name:newsletter_name
        }
        axios.post(`${window.server_url}/newsletter`,jsondata)
        .then(()=>{
            document.getElementById('success_submit').style.zIndex=100
    
            setTimeout(()=>{
    
                document.getElementById('success_submit').style.zIndex=-100
            },2000)
            document.getElementById('newsletter_name').value=""
            document.getElementById('newsletter_email').value=""
        })
        .catch(err=>console.log(err))
    }
    function search_book(){
        const book = document.getElementById('search_book_value').value
        window.open('/books-search?title='+book,'_self')
    }
    books_obj.length&&console.log(books_obj[0].primary_image_name)
    return(
        <section id ='books_container'>
            <Header/>
            <div className="tile is-ancestor is-vertical">
                {/* for top content */}
                <div id='top_content_cont' className="tile is-parent">
                    <div className="tile is-parent">
                    <div className="tile is-child notification is-white">
                        {books_main_obj.length&&parse(books_main_obj[0].books_top_content)}
                    </div>
                    </div>
                    
                </div>

                {/* for publication,email,search... */}
                <div id='publication_email_search_cont' className="tile is-parent">
                    <div className="tile is-9 is-parent">
                        <div className="tile is-child notification is-white">
                        {books_main_obj.length&&parse(books_main_obj[0].books_publication_content)}
                        </div>
                    </div>

                    <div className="tile is-parent is-vertical">
                        <div className="tile is-12 is-child">
                        <div class="field has-addons">
                          <div class="control">
                            <input id='search_book_value' class="input" type="text" placeholder="Find a Book"/>
                          </div>
                          <div class="control">
                            <a onClick={()=>search_book()} class="button is-info">
                              Search
                            </a>
                          </div>
                        </div>
                        </div>
                        <div  className="tile is-child notification is-success ">
                            {
                                books_pages.length&&books_pages.map(obj=>{
                                    return(
                                        <div>
                                            <a href={window.client_url+'/books-pages/'+obj.page_title.replaceAll(' ','-')}>{obj.page_title}</a>

                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{padding:0}} className="tile is-child">
                            <form onSubmit={(e)=>submit_newsletter(e)} id = 'main_newsletter' className="is-child notification is-white box">
                                <h1>NEWSLETTER UPDATES</h1>
                                <p>Enter your details below to get notified to our latest journals and articles.</p>
                                <label>Name</label>
                                <input required={true} id='newsletter_name' className="input" type="text" placeholder="Your Name *"/>
                                <label>Email</label>
                                <input required={true} id='newsletter_email' className="input" type="email" placeholder="Email *"/>
                                <h3 className='block'>I accept all the submitting articles</h3>
                                <a>Terms &amp; conditions</a>
                                <br className='block'/>
                                <button id='main_newsletter_btn' className="button is-primary">Submit Now</button>
                            </form> 
                        </div>
                    </div>
                </div>
            </div>
            
            {/* books preview */}
            <h1 className='notification is-size-3 is-white'>Featured Products:</h1>
            <Carousel 
            
            itemClass="multi_carousel_item"
            containerClass="notification is-white"
            responsive={responsive}
            infinite={true}
            >
                {
                    books_obj.map(obj=>{
                        if(obj.price===0){
                            return(
                                    <div>
                                <Link style={{textDecoration:'none'}} to={`/book/${obj.title.replaceAll(' ','-')}`}>

                                        <img style={{height:'250px'}} className='image' src={`/resources/books_resources/${obj.primary_image_name}`} />
                                        <h2 className='mr-6'>{obj.title}</h2>
                                        <h2 className='has-text-success has-text-bold is-size-5'>Free</h2>
                                </Link>

                                    </div>
                            )
                        }
                        else{
                            return(
                                    <div>
                                <Link style={{textDecoration:'none'}} to={`/book/${obj.title.replaceAll(' ','-')}`}>

                                        <img style={{height:'250px'}} className='image' src={`/resources/books_resources/${obj.primary_image_name}`} />
                                        <h2 className='mr-6'>{obj.title}</h2>
                                        <h2 className='has-text-success has-text-bold is-size-5'>${obj.price}</h2>
                                </Link>

                                    </div>
                            )
                        }
                    })
                }
            </Carousel>
            <h1 className='notification is-size-3 is-white'>Open Access Books:</h1>
            <div className="mx-4 columns is-multiline">
                {
                    books_obj.map(obj=>{
                        if(obj.price===0){
                            return(
                                <div className='column is-one-quarter'>
                                <Link to={`/book/${obj.title.replaceAll(' ','-')}`}>

                                    <img style={{height:'250px'}} className='image' src={`/resources/books_resources/${obj.primary_image_name}`} />

                                    <h2 className='mr-6'>{obj.title}</h2>
                                    <h2 className='has-text-success has-text-bold is-size-5'>Free</h2>
                                </Link>

                                </div>
                            )
                        }
                        
                    }
                        
                    )
                }
            </div>
            <h1 className='notification is-size-3 is-white'>Books:</h1>
            <div className="mx-4 columns is-multiline">
                {
                    books_obj.map(obj=>{
                        if(obj.price!==0){
                            return(
                                <div className='column is-one-quarter'>
                                <Link to={`/book/${obj.title.replaceAll(' ','-')}`}>

                                    <img style={{height:'250px'}} className='image' src={`/resources/books_resources/${obj.primary_image_name}`} />
                                    <h2 className='mr-6'>{obj.title}</h2>
                                    <h2 className='has-text-success is-size-5'>${obj.price}</h2>
                                </Link>

                                </div>
                            )
                        }
                    }
                        
                    )
                }
            </div>
            <Footer/>
        </section>
    )
}

export default Books;