import { useEffect,useState,StrictMode } from 'react';
import './css/bulma.css'
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import axios from 'axios';

import Front_page from './components/front_page/front_page';
import Article_processing from './components/for_authors_pg/article_processing';
import Plagiarism_policy from './components/for_authors_pg/plagiarism';
import Conflicts_of_interest from './components/for_authors_pg/conflicts_of_interest';
import Authors_rights from './components/for_authors_pg/authors_rights';
import Publication_ethics from './components/for_authors_pg/publication_ethics';
import Copyright_policy from './components/for_authors_pg/copyright';
import For_reviewer from './components/Information/for_reviewer';
import For_editor from './components/Information/for_editor';
import Open_access_policy from './components/Information/open_access_policy';
import Peer_review_policy from './components/Information/peer_review_policy';
import Journals from './components/journals/journals';
import Thesis from './components/thesis_page/thesis';
import Home from './components/dynamic_journal/home';
import Back_office_index from './components/back_office/back_office_index';
import About from './components/about_page/about';
import Submit_manuscript from './components/submit_manuscript';
import Impact_factors from './components/impact_factors';
import Article_main from './components/article_main';
import Back_journal_archive from './components/back_office/admin_page/back_journal_archive';
import Back_article_edit from './components/back_office/admin_page/back_article_edit';
import Articles_by_session from './components/dynamic_journal/articles_by_session'
import Search_result from './components/front_page/search_result'
import Payments from './components/payments'
import Contact_us from './components/contact_us'
import Privacy_policy from './components/privacy_policy'
import Join_as_editor from './components/join_as_editor'
import Manage_pages from './components/back_office/admin_page/manage_pages'
import Books from './components/books'
import Books_pages from './components/books_pages'
import Books_preview from './components/books_preview'
import Books_search from './components/books_search'



// window.server_url = 'http://localhost:2000';
// window.client_url = 'http://localhost:3000';

window.server_url = 'https://aipublisher.org:2020';
window.client_url = 'http://aipublisher.org'



//for scrollinig to top on reaload
window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}
function App() {
  const [journal_obj,set_journal_obj] = useState([]);
  const [article_obj,set_article_obj] = useState([]);
  const [books_obj,set_books_obj] = useState([])
  useEffect(()=>{
      axios.all([
        axios.get(`${window.server_url}/journals/journal_intro`),
        axios.get(`${window.server_url}/article_db/doi_numbers`),
        axios.get(window.server_url+'/books/intro')
      ])
      .then(axios.spread((...responses)=>{
        set_journal_obj(responses[0].data);
        set_article_obj(responses[1].data);
        set_books_obj(responses[2].data)
      }))
      .catch(error=>console.log(error))
      window.hide_loader();
  },[])
  return (
    <StrictMode>
      <div style={{maxWidth:'1260px'}} className = "container">
          <BrowserRouter>
            <Switch>
              <Route exact path='/' component={Front_page}/>
              <Route path='/about' component={About}/>
              <Route path='/article-processing' component={Article_processing}/>
              <Route path='/plagiarism-policy' component={Plagiarism_policy}/>
              <Route path='/conflicts-of-interest' component={Conflicts_of_interest}/>
              <Route path='/authors-rights' component={Authors_rights}/>
              <Route path='/publication-ethics' component={Publication_ethics}/>
              <Route path='/copyright-policy' component={Copyright_policy}/>
              <Route path='/for-reviewer' component={For_reviewer}/>
              <Route path='/for-editor' component={For_editor}/>
              <Route path='/open-access-policy' component={Open_access_policy}/>
              <Route path='/peer-review-policy' component={Peer_review_policy}/>
              <Route path='/journals-section' component={Journals}/>
              <Route path='/thesis' component={Thesis}/>
              <Route path='/back-office' component={Back_office_index}/>
              <Route path='/submit' component={Submit_manuscript}/>
              <Route path='/impact_factors' component={Impact_factors}/>
              <Route path='/back_art_edit' component={Back_article_edit}/>
              <Route path='/articles' component={Articles_by_session}/>
              <Route path='/search' component={Search_result}/>
              <Route path='/payments' component={Payments}/>
              <Route path='/contact' component={Contact_us}/>
              <Route path='/privacy-policy' component={Privacy_policy}/>
              <Route path='/join-as-editor' component={Join_as_editor}/>
              <Route path='/manage-pages' component={Manage_pages}/>
              <Route path='/books' component={Books}/>
              <Route path='/books-pages' component={Books_pages}/>
              <Route path='/books-search' component={Books_search}/>
              {
                journal_obj.map(obj=>(
                  
                  <Route key={`app ${obj.journal_name}`} path={`/${encodeURI(obj.journal_slug)}`} render={()=>(
                    <Home current_volume={obj.current_volume} current_issue={obj.current_issue} id={obj.id} journal_name={obj.journal_name} chief_editor={obj.chief_editor} issn={obj.issn} frequency={obj.frequency} language={obj.journal_language} origin={obj.journal_origin} publisher={obj.journal_publisher} impact_factor={obj.impact_factor}/>
                  )}/>

                ))
              }
              {
                journal_obj.map(obj=>(
                  <Route key={`archive ${obj.id}`} path={`/journal_id=${obj.id}`} render={()=>(
                    <Back_journal_archive journal_id={obj.id}/>
                  )}/>

                ))
              }
              {
                books_obj.map(obj=>(
                  <Route key={`${obj.id} books`} path={`/book/${obj.title.replaceAll(' ','-')}`} render={()=>{
                    return(
                      <Books_preview id={obj.id}/>
                    )
                  }}/>
                ))
              }
              {
                article_obj.map(obj=>(
                  <Route key={`${obj.id}`} path={`/article_id=${obj.id}`} render={()=>{
                    
                    return(

                      <Article_main id={obj.id}/>

                    )
                  }}/>
                ))
              }
            </Switch>
          </BrowserRouter>
      </div>
    </StrictMode>

  );
}
window.show_loader = ()=>{
  document.getElementById('loading_container').style.display='block';
}
window.hide_loader = (is_top=false)=>{
  document.getElementById('loading_container').style.display='none';

  if(is_top){
    window.scroll(0,0);

  }
}


//lazy mode is not loading css , after everything find a way to access css to needed js files and implement lazy if you want performance.
//although we have a global one in index.html we are creating this component to use with the Suspense element because supsense
//fallback argument only takes element and not functions.
const Loading_screen = ()=>{
  return(
    <div style={{height:'100vh',
      width:'100vw',
      backgroundColor:'#222d32',
      position:'fixed',
      left:'0'
    }}>
      <div class="loading">

      </div>
    </div>
  )
}

export default App;
